@import "./iconfont.css";

html,
body,
#root {
    margin: 0;
    background-color: #fff !important;
    width: 100%;
    height: 100%;
}

body::-webkit-scrollbar {
    display: none;
}

* {
    box-sizing: border-box;
}

input {
    -webkit-appearance: none;
}

/* 布局 */

.layout {
    background: linear-gradient(141deg, rgba(99, 138, 246, 1) 0%, rgba(77, 121, 243, 1) 100%);
    box-shadow: 0px 2px 4px 0px rgba(175, 175, 175, 0.5);
}

.am-list-item.am-input-item {
    padding-left: 0 !important;
}

.meeting-page .ant-spin-container {
    height: 100vh;
}

.meeting-page .ant-spin-nested-loading>div>.ant-spin {
    max-height: 100vh;
}

.ant-cascader-input.ant-input {
    height: 100%;
    font-weight: 400;
    color: rgba(96, 98, 102, 1);
    font-size: 16px;
    background-color: #fff;
    border: 1px solid rgba(204, 213, 216, 1);
}

.meet-footer .ant-dropdown-menu-item-active {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.rightMenuDrawer .ant-drawer-content {
    background: none;
}

.rightMenuDrawer .ant-drawer-content-wrapper {
    box-shadow: none !important;
}

.rightMenuDrawer .ant-drawer-content .ant-drawer-body {
    padding: 0;
}

.navDrawer {
    transition: none !important;
}

.listMode-Collapse {
    height: 42px !important;
}

.listMode-Collapse .ant-collapse-arrow {
    color: #fff !important;
}

.ant-collapse-icon-position-right>.listMode-Collapse.ant-collapse-item>.ant-collapse-header {
    padding: 0 0.1rem;
}

.ant-carousel .speaker-mode-carousel .slick-list .slick-slide {
    pointer-events: auto;
}

.reply-switch.ant-switch-checked {
    background-color: rgb(126, 211, 33) !important;
}

/* 画廊模式去掉滚动条 */
#remoteMeetVideoBox::-webkit-scrollbar {
    display: none;
}

.waiting_room_progress .ant-progress-text {
    color: #fff !important;
}

/* 参会人 */
#userListSelect.ant-select-selector {
    height: 48px;
}

.navBar-badge .ant-badge-dot {
    box-shadow: 0 0 0 1px rgb(126, 211, 33) !important;
    width: 0.1rem;
    height: 0.1rem;
}

.navbar-download-modal .ant-modal-close-x {
    width: 0.56rem;
    height: 0.56rem;
}

/* swiper */

.speaker-swiper.swiper-button-prev:after {
    content: "";
}

.speaker-swiper.swiper-button-prev {
    font-size: 0.2rem;
}

.ant-drawer-content::-webkit-scrollbar {
    display: none;
}

/*账号*/
.accountDrawer .ant-drawer-content {
    background: none;
}

.accountDrawer .ant-drawer-content-wrapper {
    box-shadow: none !important;
    width: 317px !important;
}

/* 首页头像 */
.avatar-badge:hover .ant-badge-dot {
    width: 10px;
    height: 10px;
}

.ant-picker:hover,
.ant-picker-focused {
    border-color: #0091ff !important;
}

.ant-picker-focused {
    border-color: #0091ff !important;
}

.ant-picker:hover {
    border-color: #0091ff !important;
}

/* 选择我的语言 */
.meet-modal-languages .ant-btn-primary {
    background: #fff !important;
    border-color: #fff !important;
}

/* 字幕通知 */

.meet-api-notification {
    background-color: rgba(0, 145, 255, 0.8) !important;
    color: #fff;
    padding: 8px 24px !important;
    width: 300px !important;
}

.meet-api-notification .ant-notification-notice-message {
    color: #fff !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.meet-api-notification .ant-notification-notice-close {
    top: 8px !important;
    color: #fff !important;
}

.meet-api-notification .ant-notification-notice-with-icon span {
    font-size: 20px;
    line-height: 20px;
}

/* 登录页区号选择 */
.code-search .ant-select-selector {
    padding: 0 5px !important;
    border: 0 !important;
    border-right: 1px solid #d9d9d9 !important;
    height: 0.32rem !important;
}

.code-search .ant-select-item {
    width: 200px !important;
}

.code-search.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0 !important;
    line-height: 0.3rem;
}

.code-search.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 0;
}

/* 共享设置 */
.ant-popover-inner {
    background: rgba(5, 5, 5, 0.8) !important;
}

.ant-popover-arrow-content::before {
    background: none;
}

.ant-popover-arrow-content {
    background-color: rgba(5, 5, 5, 0.8);
}

.ant-popover-placement-right>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-rightTop>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-rightBottom>.ant-popover-content>.ant-popover-arrow {
    border-bottom-color: rgba(5, 5, 5, 0.8) !important;
    border-left-color: rgba(5, 5, 5, 0.8) !important;
}

.ant-popover-placement-left>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-leftTop>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-leftBottom>.ant-popover-content>.ant-popover-arrow {
    border-top-color: rgba(5, 5, 5, 0.8) !important;
    border-right-color: rgba(5, 5, 5, 0.8) !important;
}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
    border-top-color: rgba(5, 5, 5, 0.8) !important;
    border-left-color: rgba(5, 5, 5, 0.8) !important;
}

/* 关闭白板提示框*/
.whiteboard-modal .ant-modal-confirm-btns {
    display: none;
}

.whiteboard-modal-content {
    font-weight: 400;
    color: #788DC7;
    text-align: center;
    font-size: 14px;
    line-height: 20px;

}

.whiteboard-modal-content .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
}

.whiteboard-modal-content .btns {
    display: flex;
    justify-content: center;
}

.whiteboard-modal-content .btn1,
.whiteboard-modal-content .btn2 {
    color: #788DC7;
    font-size: 14px;
    width: 90px;
    height: 36px;
    border-radius: 5px;
    margin: 19px 14px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
}

.whiteboard-modal-content .btn1 {
    background: #F3F6FF;
    color: #7C8EC3;
}

.whiteboard-modal-content .btn2 {
    background: #1C4CD1;
    color: #fff;
}