.downloadPage {
    background: #fff;
    height: 100vh;
}

.nav {
    height: 0.6rem;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3.6rem;
    font-size: 0.16rem;
    font-family: Alibaba PuHuiTi 2.0;
    font-weight: normal;
    color: #6A6A6A;
}

.nav img {
    width: 0.78rem;
    height: 0.35rem;
}

.nav div {
    cursor: pointer;
}

.formBox {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 1.13rem 6rem 0 6rem;
}


.formItem {
    margin-bottom: 0.26rem;
    display: flex;
    align-items: baseline;
}

.label {
    margin-right: 0.15rem;
    width: 1.7rem;
    font-size: 0.18rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 0.25rem;
    color: #555555;
    text-align: right;
}

.label span {
    vertical-align: top;
    color: red;
    font-size: 0.12rem;
}

.inputWrapper {
    display: flex;
    width: 100%;
}

.input {
    height: 0.36rem;
    line-height: 0.36rem;
    width: 100%;
    border: 1px solid #D2D6E3;
    border-radius: 0.02rem;
    padding-left: 0.1rem;
    font-size: 0.14rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 0.20rem;
    color: #788DC7;
}

.input input {
    font-size: 0.14rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 0.20rem;
    color: #788DC7;
}

.input:focus {
    outline: none;
}

.input .ant-input::placeholder {
    font-size: 0.14rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 0.24rem;
    color: #D2D6E3;
}

.input::placeholder {
    font-size: 0.14rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 0.24rem;
    color: #D2D6E3;
}

.textarea {
    width: 100%;
    height: 1.52rem;
    border-radius: 0.02rem;
    padding: 0.09rem 0.10rem;
    font-size: 0.14rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 0.2rem;
    border: 1px solid #D2D6E3;
    resize: none;
}

.textarea:focus {
    outline: none;
}

.textarea::placeholder {
    font-size: 0.14rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #D2D6E3;
    line-height: 0.2rem;
}

.confirmBtn {
    width: 1.34rem;
    height: 0.50rem;
    border-radius: 0.02rem;
    font-size: 0.2rem;
    font-family: MicrosoftYaHei;
    line-height: 0.50rem;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    background: #4F7BEE;
    border: none;
}

.confirmBtnDisabled {
    opacity: 0.7;
}