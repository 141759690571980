@font-face {
  font-family: "iconfont"; /* Project id 1956615 */
  src: url('//at.alicdn.com/t/font_1956615_v0is88bi1as.woff2?t=1622531520306') format('woff2'),
       url('//at.alicdn.com/t/font_1956615_v0is88bi1as.woff?t=1622531520306') format('woff'),
       url('//at.alicdn.com/t/font_1956615_v0is88bi1as.ttf?t=1622531520306') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mac:before {
  content: "\e61b";
}

.icon-windows:before {
  content: "\e93c";
}

.icon-anzhuo:before {
  content: "\e612";
}

.icon-jilu1:before {
  content: "\e673";
}

.icon-blur:before {
  content: "\e65b";
}

.icon-xiazai:before {
  content: "\e623";
}

.icon-fanhui:before {
  content: "\e641";
}

.icon-gengduo:before {
  content: "\e638";
}

.icon-qunzu:before {
  content: "\e748";
}

.icon-user:before {
  content: "\e7c3";
}

.icon-tongzhi:before {
  content: "\e64d";
}

.icon-xiashuangjiantou:before {
  content: "\e617";
}

.icon-zuzhi:before {
  content: "\e626";
}

.icon-yonghu:before {
  content: "\e711";
}

.icon-duoren:before {
  content: "\e62e";
}

.icon-pin:before {
  content: "\e635";
}

.icon-screen:before {
  content: "\e62d";
}

.icon-biaodanzujian-xialakuang:before {
  content: "\eb94";
}

.icon-id:before {
  content: "\e66c";
}

.icon-jilu:before {
  content: "\e66b";
}

.icon-liaotian:before {
  content: "\e64c";
}

.icon-bofang:before {
  content: "\e60c";
}

.icon-wenjian:before {
  content: "\e793";
}

.icon-zanting:before {
  content: "\e614";
}

.icon-video:before {
  content: "\e7a3";
}

.icon-select:before {
  content: "\e608";
}

.icon-muted:before {
  content: "\e707";
}

.icon-unmuted:before {
  content: "\e733";
}

.icon-video_off:before {
  content: "\e666";
}
