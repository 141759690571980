@import "./_mixins.scss";

$borderRadius: 4px;
$zindex0: 0;
$zindex1: 1;
$zindex2: 2;
$zindex3: 3;
$zindex10: 10;
$thumbnailToolbarHeight: 22px;
$thumbnailIndicatorBorder: 2px;
$thumbnailIndicatorSize: $thumbnailToolbarHeight;
$thumbnailVideoMargin: 2px;
$thumbnailsBorder: 2px;
$thumbnailVideoBorder: 2px;
$filmstripToggleButtonWidth: 17px;
$dominantSpeakerBg: #165ecc;
$thumbnailPictogramColor: #fff;
$sidebarWidth: 375px;

$slideWidth: 1.92rem; //1.28rem
$slideHeight: 1.01rem; //0.67rem

$audioLevelShadow: rgba(9, 36, 77, 0.9);
$audioLevelBg: #44a5ff;

$meetBg: rgba(64, 64, 64, 1);

@mixin circle($diameter) {
    width: $diameter;
    height: $diameter;
    border-radius: 50%;
}

/*会议-菜单-提示*/
.meet-menu-tooltip {
    font-size: 10px !important;
}

.swiper-slide {
    width: $slideWidth !important;
}

// 主讲模式
.videocontainer {
    position: relative;
    text-align: center;
    cursor: pointer;
    height: 1.01rem; // 0.93rem
    width: 1.92rem;
    width: $slideWidth;
    height: $slideHeight;
    border: 1px solid #000;
    box-sizing: border-box;

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(64, 64, 64, 1);
        // border-radius: $borderRadius;
        width: 100%;
        height: 100%;
    }

    &__toolbar,
    &__toptoolbar {
        position: absolute;
        left: 0;
        pointer-events: none;
        z-index: $zindex10;
        width: 100%;
        box-sizing: border-box;
        padding: 0 5px 0 5px;
        margin-top: 2.6%;
        pointer-events: none;

        * {
            pointer-events: auto;
        }

        // .indicator-container {
        //     display: inline-block;
        //     float: left;
        //     pointer-events: all;
        // }

        // .moderator {
        //     width: 0.2rem;
        //     height: 0.2rem;
        //     background: rgba(0, 0, 0, 0.5);
        //     border-radius: 6px;
        //     color: #ffffff;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     float: right;

        //     img {
        //         width: 0.13rem;
        //     }
        // }
    }

    &__toolbar {
        bottom: 0;
        height: $thumbnailToolbarHeight;
        padding: 0 5px 0 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.6%;

        .username {
            // position: absolute;
            color: rgba(0, 0, 0, 0);
            max-width: 1.52rem;
            height: 0.28rem;
            background: rgba(26, 26, 26, 0.43);
            border-radius: 0.24rem;
            padding: 0 0.06rem;
            font-size: 0.12rem;
            color: #ffffff;
            line-height: 0.28rem;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .muted {
            width: 0.2rem;
            height: 0.2rem;
            background: rgba(0, 0, 0, 0.5s);
            border-radius: 0.06rem;
            color: rgb(224, 32, 32);
            display: flex;
            align-items: center;
            justify-content: center;

            .iconfont {
                font-size: 0.13rem;
            }
        }
    }

    &__toptoolbar {
        $toolbarIconMargin: 5px;
        top: 0;
        padding-bottom: 0;
        text-align: left;

        .moderator {
            width: 0.2rem;
            height: 0.2rem;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 0.06rem;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            float: right;

            img {
                width: 0.13rem;
            }
        }
    }

    &.shift-right {
        &#largeVideoContainer {
            margin-left: $sidebarWidth;
            width: calc(100% - #{$sidebarWidth});
        }
    }

    .avatar-container {
        @include maxSize(0.59rem); //0.69rem
        @include absoluteAligning();
        display: flex;
        justify-content: center;
        // height: 50%;
        height: 70%;
        width: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;

        .userAvatar {
            width: 0.47rem;
            height: 0.47rem;
            border-radius: 50%;
        }

        .userAvatar1 {
            width: 0.47rem;
            height: auto;
            object-fit: cover;
            border-radius: 50%;
        }

        // .closeText {
        //     white-space: pre;
        //     font-size: 0.12rem;
        //     color: #ffffff;
        //     line-height: 17px;
        // }
    }

    video {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

// 画廊模式
.galley-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    .galley-main {
        width: 100%;
        min-height: 100vh;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 auto;
        box-sizing: border-box;
    }

    // .galley-main::-webkit-scrollbar {
    //     display: none;
    // }

    /* .wrap:包裹矩形的div，用来控制矩形的大小 */
    .galley-participant-wrap {
        box-sizing: border-box;
        width: 50%;
    }

    /* .box 矩形div，宽度是.wrap的百分百，这主要是为了方便高度的计算 */
    .galley-participant-box {
        cursor: pointer;
        width: 100%;
        /*防止矩形被里面的内容撑出多余的高度*/
        height: 0px;
        padding-bottom: 56.25%;
        position: relative;
        background: rgba(64, 64, 64, 1);
        border: 1px solid #000000;
        overflow: hidden;

        .videocontainer {
            &__background {
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(64, 64, 64, 1);
                width: 100%;
                height: 100%;
            }

            &__toolbar,
            &__toptoolbar {
                position: absolute;
                left: 0;
                pointer-events: none;
                z-index: $zindex10;
                width: 100%;
                box-sizing: border-box;
                pointer-events: none;

                * {
                    pointer-events: auto;
                }

                // .indicator-container {
                //     display: inline-block;
                //     float: left;
                //     pointer-events: all;
                // }
            }

            &__toolbar {
                bottom: 0;
                height: $thumbnailToolbarHeight;
                padding: 0 5px 0 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 2.6%;

                .username {
                    max-width: 1.58rem;
                    height: 0.31rem;
                    background: rgba(26, 26, 26, 0.43);
                    border-radius: 0.24rem;
                    padding: 0 0.06rem;
                    font-size: 0.12rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 0.31rem;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .muted {
                    width: 0.28rem;
                    height: 0.28rem;
                    color: rgb(224, 32, 32);
                    background: rgba(0, 0, 0, 0.5);
                    border-radius: 0.06rem;
                    text-align: center;
                    line-height: 0.28rem;

                    .iconfont {
                        font-size: 0.17rem;
                    }
                }
            }

            &__toptoolbar {
                $toolbarIconMargin: 5px;
                top: 0;
                padding-bottom: 0;
                text-align: left;
                margin-top: 2.6%;

                .moderator {
                    width: 0.28rem;
                    height: 0.28rem;
                    background: rgba(0, 0, 0, 0.5);
                    border-radius: 0.06rem;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    float: right;

                    img {
                        width: 0.17rem;
                    }
                }

                // .indicator {
                //     margin-left: 5px;
                //     margin-top: $toolbarIconMargin;
                // }

                // .indicator-container:nth-child(1) .indicator {
                //     margin-left: $toolbarIconMargin;
                // }

                // .indicator-container {
                //     display: inline-block;
                //     vertical-align: top;

                //     .popover-trigger {
                //         display: inline-block;
                //     }
                // }

                // .connection-indicator,
                // .indicator {
                //     position: relative;
                //     font-size: 8px;
                //     text-align: center;
                //     line-height: $thumbnailIndicatorSize;
                //     padding: 0;
                //     @include circle($thumbnailIndicatorSize);
                //     box-sizing: border-box;
                //     z-index: $zindex3;
                //     background: $dominantSpeakerBg;
                //     color: $thumbnailPictogramColor;
                //     border: $thumbnailIndicatorBorder solid $thumbnailPictogramColor;

                //     .indicatoricon {
                //         top: 50%;
                //         left: 50%;
                //         position: absolute;
                //         @include absoluteAligning();
                //     }

                //     .connection {
                //         position: relative;
                //         display: inline-block;
                //         margin: 0 auto;
                //         left: 0;
                //         @include transform(translate(0, -50%));

                //         &_empty,
                //         &_lost {
                //             color: #8b8b8b; /*#FFFFFF*/
                //             overflow: hidden;
                //         }

                //         &_full {
                //             @include topLeft();
                //             color: #ffffff; /*#15A1ED*/
                //             overflow: hidden;
                //         }

                //         &_ninja {
                //             font-size: 1.5em;
                //         }
                //     }

                //     .icon-gsm-bars {
                //         cursor: pointer;
                //         font-size: 1em;
                //     }
                // }

                // .hide-connection-indicator {
                //     display: none;
                // }
            }

            // &__hoverOverlay {
            //     background: rgba(0, 0, 0, 0.6);
            //     border-radius: $borderRadius;
            //     position: absolute;
            //     top: 0px;
            //     left: 0px;
            //     width: 100%;
            //     height: 100%;
            //     visibility: hidden;
            //     z-index: $zindex2;
            // }

            &.shift-right {
                &#largeVideoContainer {
                    margin-left: $sidebarWidth;
                    width: calc(100% - #{$sidebarWidth});
                }
            }
        }

        .avatar-container {
            @include maxSize(0.86rem); //0.69rem
            @include absoluteAligning();
            display: flex;
            justify-content: center;
            height: 70%;
            width: auto;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;

            .userAvatar {
                width: 0.69rem;
                height: 0.69rem;
                border-radius: 50%;
            }

            .userAvatar1 {
                width: 0.69rem;
                height: auto;
                object-fit: cover;
                border-radius: 50%;
            }

            // .closeText {
            //     white-space: pre;
            //     font-size: 0.12rem;
            //     font-family: PingFangSC-Regular, PingFang SC;
            //     font-weight: 400;
            //     color: #ffffff;
            //     line-height: 17px;
            // }
        }

        video {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .picture1-warp,
    .picture2-warp {
        position: absolute;
        z-index: 100;
        width: 3.2rem !important;
        height: 1.8rem;
        padding-bottom: 0;

        .galley-participant-box {
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.47);
            border-radius: 0.1rem;
        }
    }

    // 画中画1
    .picture1-warp {
        top: 0.38rem;
    }

    .picture1-warp.right,
    .picture2-warp.right {
        right: 0.38rem;
    }

    .picture1-warp.left,
    .picture2-warp.left {
        left: 0.38rem;
    }

    // 画中画2
    .picture2-warp {
        top: 2.48rem;
    }

    // 主窗口
    .mainPicture-warp {
        width: 100% !important;
        height: 100%;
        position: relative;

        .galley-participant-box {
            height: 100%;
            background: none;
            padding-bottom: 0;
            border: none !important;

            .videocontainer__background {
                background-color: rgba(0, 0, 0, 0.58);
            }

            .videocontainer__toptoolbar {
                display: none;
            }

            .videocontainer__toolbar {
                position: absolute;
                left: 0.2rem;
                top: 10.5%;
                z-index: 100;
                height: 0.46rem;
                line-height: 0.46rem;
                background: rgba(0, 0, 0, 0.58);
                border-radius: 0.23rem;
                padding: 0 0.2rem;
                display: flex;
                align-items: center;
                width: fit-content;

                .muted {
                    display: none;
                }

                .username {
                    background: none;
                }
            }

        }
    }

    // 画中画小窗靠右时主窗样式
    .mainPicture-warp.left .videocontainer__toolbar {
        left: 0.2rem;
    }

    // 画中画小窗靠左时主窗样式
    .mainPicture-warp.right .videocontainer__toolbar {
        right: 0.2rem;
        left: inherit
    }

    // 主画中画占位
    .picture_occupied {
        color: #FFFFFF;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
    }

    // 小画中画占位
    .small_picture_occupied {
        color: #FFFFFF;
        background: #404040;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.1rem;
    }
}

.galley-container::-webkit-scrollbar {
    display: none;
}

// 两人模式左上角
.topleft_usercontainer.videocontainer {
    position: absolute;
    top: 0.23rem;
    left: 0.3rem;
    z-index: 100;
    text-align: center;
    cursor: pointer;
    height: 1.88rem;
    width: 3.12rem;
    box-shadow: 2px 0px 8px 0px #0091ff;
    border: 2px solid #0091ff;
    background: #404040;

    .avatar-container {
        .userAvatar {
            width: 0.52rem;
            height: 0.52rem;
        }

        .userAvatar1 {
            width: 0.52rem;
        }
    }

    .videocontainer {
        &__toptoolbar {
            .moderator {
                width: 0.28rem;
                height: 0.28rem;

                img {
                    width: 0.16rem;
                }
            }
        }

        &__toolbar {
            .muted {
                width: 0.28rem;
                height: 0.28rem;

                .iconfont {
                    font-size: 0.14rem;
                }
            }

            .username {
                border-radius: 0.06rem;
            }
        }
    }

}

// 主讲人
.speaker_usercontainer {
    width: 100%;
    height: 100%;
    position: relative;

    .speakerIcon {
        position: absolute;
        right: 0.3rem;
        top: 10.5%;
        z-index: 100;
        height: 0.46rem;
        background: rgba(0, 0, 0, 0.58);
        border-radius: 0.23rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.46rem;
        padding: 0 0.2rem;
        display: flex;
        align-items: center;
        width: fit-content;
    }

    .speakerIcon img {
        width: 0.28rem;
        height: 0.28rem;
        margin-right: 0.06rem;
    }

    .speakerIcon .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
    }

    .avatar-container {
        @include maxSize(1rem); //0.69rem
        @include absoluteAligning();
        display: flex;
        justify-content: center;
        height: 70%;
        width: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;

        .userAvatar {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
        }

        .userAvatar1 {
            width: 0.47rem;
            height: auto;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .noVideoTextTwo {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.16rem;
        color: rgba(255, 255, 255, 1);
        font-size: 0.3rem;
    }
}

.apply_for_join_notification {
    height: 0.4rem;
    line-height: 0.4rem;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 0.28rem;
    padding: 0 0.2rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0.12rem;
    z-index: 1000;
    display: flex;
    align-items: center;
    font-size: 0.12rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;

    .username {
        margin-right: 0.2rem;
    }

    .btn {
        width: 0.6rem;
        height: 0.18rem;
        background: #6dd400;
        border-radius: 0.09rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin: 0 0.05rem;
        cursor: pointer;
    }

    .btn:first-child {
        background: #6dd400;
    }

    .btn:last-child {
        background: rgba(250, 100, 0, 1);
    }

    img {
        width: 0.12rem;
        height: 0.12rem;
        margin-right: 0.03rem;
    }

    .icon-user1 {
        width: 0.14rem;
        height: 0.14rem;
        margin-right: 0.1rem;
        -webkit-animation: scaleout 1s infinite ease-in-out;
        animation: scaleout 1s infinite ease-in-out;
    }

    @-webkit-keyframes scaleout {
        0% {
            -webkit-transform: scale(1);
        }

        100% {
            -webkit-transform: scale(1.1);
            opacity: 0;
        }
    }

    @keyframes scaleout {
        0% {
            transform: scale(1);
            -webkit-transform: scale(1);
        }

        100% {
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
            opacity: 0;
        }
    }
}

/* 主讲音频 */
.speaker-audioindicator-container {
    position: relative;
    width: 0.5rem;
    height: 100%;
}

.speaker-audioindicator-container .audioindicator-container {
    bottom: 0.03rem !important;
    height: 100%;
}

// 音频音量点
.speaker-audioindicator-container .audioindicator-container,
.galley-container .audioindicator-container,
.videocontainer>span.audioindicator,
.videocontainer>.audioindicator-container {
    position: absolute;
    left: 50%;
    bottom: 0.05rem;
    display: flex;
    margin-left: -0.2rem;
    width: 0.5rem;
    z-index: $zindex2;
    border: none;

    .audiodot-top,
    .audiodot-bottom,
    .audiodot-middle {
        opacity: 0;
        display: inline-block;
        @include circle(0.05rem);
        background: rgba(0, 0, 0, 0);
        margin: 1px 0 1px 0;
        transition: opacity 0.25s ease-in-out;
        -moz-transition: opacity 0.25s ease-in-out;
    }

    span.audiodot-top::after,
    span.audiodot-bottom::after,
    span.audiodot-middle::after {
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        -webkit-filter: blur(0.5px);
        filter: blur(0.5px);
        background: $audioLevelBg;
    }
}

.videocontainer>.audioindicator-container {
    height: 0.2rem;
}

.dynamic-shadow {
    transition: box-shadow 0.3s ease;
}

// 录屏提醒
.media_recorder_prompt_box {
    position: fixed;
    top: 25%;
    left: 20px;
    height: 30px;
    z-index: 200;
    font-size: 0.14rem;
    font-family: PingFangSC-Regular;
    line-height: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.58);
    border-radius: 23px;
    padding: 0 20px;
}

.media_recorder_prompt {
    background: #fff;
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 50%;
    margin-right: 10px;

}

.media_recorder_prompt div {
    background: red;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-animation: scaleout 1s infinite ease-in-out;
    animation: scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes scaleout {
    0% {
        -webkit-transform: scale(1);
    }

    100% {
        -webkit-transform: scale(1.1);
        opacity: 0.2;
    }
}

@keyframes scaleout {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }

    100% {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        opacity: 0.2;
    }
}

.meet-renew-handle {
    font-size: 0.14rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    position: fixed;
    z-index: 100000;
    left: 0;
    bottom: 1.24rem;
    padding: 0.05rem 0.14rem;
    background: rgba(210, 214, 227, 0.9);
    border-radius: 0px 0.20rem 0.20rem 0px;
    transition: all 0.5s;
    transform: translate3d(0px, 0px, 0px);
    cursor: pointer;

    .img-animation {
        transform: rotate(180deg);
    }
}

.meet-renew-handle:hover {
    background: rgba(81, 177, 249, 0.9);
    color: #FFFFFF;
}

.meet-renew-handle-info {
    font-size: 0.12rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 0.17rem;
    color: #FFFFFF;
    position: fixed;
    z-index: 100000;
    left: 0.63rem;
    bottom: 1.24rem;
    width: 1.57rem;
    height: 0.30rem;
    background: rgba(0, 0, 0, 0.66);
    border-radius: 0.02rem;
    display: flex;
    align-items: center;
}

.meet-renew-handle.c-animation {
    transform: translate3d(-1.7rem, 0, 0px);
}

.meet-whiteboard-handle {
    position: fixed;
    z-index: 100000;
    right: 0;
    bottom: 1.67rem;
    padding: 0.16rem 0.12rem 0.16rem 0.19rem;
    background: #1C1C1C;
    border-radius: 0.3rem 0 0 0.3rem;
    cursor: pointer;

    img {
        width: 0.2483rem;
        height: 0.24rem;
    }
}

//白板操作面板

.whiteboard-button-handle {
    position: fixed;
    bottom: 110px;
    right: 0;
    z-index: 101;
    background: #747BAB;
    border-radius: 6px 0px 0px 6px;
    padding: 11px 0;
    min-width: 93px;
    transition: all 0.2s;
}

.whiteboard-button-handle-active {
    width: 18px;
    min-width: auto;
    padding-left: 6px;
}

.whiteboard-button-handle span:nth-child(1) {
    margin-left: 11px;
    color: #fff;
}

.whiteboard-button-handle img:nth-child(2) {
    width: 18.62px;
    height: 18px;
    margin-right: 11px;
    margin-left: 11px;
    cursor: pointer;
}

.whiteboard-button-handle img:nth-child(3) {
    width: 19.8px;
    height: 19.8px;
    margin-right: 14px;
    cursor: pointer;
}

.whiteboard-button-handle img:nth-child(4) {
    width: 6.89px;
    height: 15.64px;
    margin-right: 4px;
    cursor: pointer;
}

.whiteboard-button-handle .jiantou {
    transform: rotate(180deg);
}

// 批注
.meet-annotate-handle {
    font-size: 0.14rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #788DC7;
    position: fixed;
    z-index: 100000;
    right: 0;
    bottom: 1.24rem;
    padding: 0.05rem 0.14rem;
    background: rgba(210, 214, 227, 0.9);
    border-radius: 0.20rem 0px 0px 0.20rem;
    transition: all 0.5s;
    transform: translate3d(0px, 0px, 0px);
    cursor: pointer;
    user-select: none;
}

.meet-assist-handle {
    display: flex;
    align-items: center;
    background: #3C68E3;
    padding: 0.07rem 0.08rem 0.07rem 0.21rem;
    border-radius: 0.3rem 0px 0px 0.3rem;
    position: absolute;
    z-index: 100000;
    right: 0;
    bottom: 1.24rem;
    padding: 0.05rem 0.14rem;
    cursor: pointer;
    user-select: none;

    span {
        font-size: 0.16rem;
        color: #FFFFFF;
        line-height: 0.2rem;
    }

    img {
        width: 0.33rem;
        height: 0.26rem;
        margin-right: 0.1rem;
    }

    #assist-img {
        width: 0.30rem;
        height: 0.30rem;
        margin-right: 0.12rem;
    }
}

// 画中画
.pictureInPictureBox {
    position: absolute;
    top: 0.38rem;
    right: 0.38rem;
    z-index: 100;

    .picture1,
    .picture2 {
        width: 3.2rem;
        height: 1.80rem;
        background-color: #404040;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.47);
        cursor: pointer;
        position: relative;
    }

    .picture1 {
        margin-bottom: 0.24rem;
    }
}